<template>
	<article class="view-week-questions">
		<div class="site-wrapper rythm-v-l">
			<header class="page-header">
				<h1>
					Questions de la semaine
				</h1>
			</header>

			<section class="section--question rythm-v">
				<div class="toolbar"
						 data-toolbar="week-questions">

					<div class="flex-row flex-center-v flex-gap-s">
						<label for="select-week">Semaine :</label>
						<select name="select-week"
										class="vgt-select"
										@change="selectWeekForWeekQuestions">
							<option :value="selectedWeek"
											v-for="selectedWeek in weeks"
											:selected="selectedWeek == week">Semaine {{ selectedWeek }} {{ getFormattedWeekDates(selectedWeek) }}</option>
						</select>
					</div>

					<div class="flex-row flex-center-v flex-gap-s flex-nowrap">
						<label for="select-year">Année :</label>
						<select name="select-year"
										class="vgt-select"
										@change="selectYearForWeekQuestions">
							<option :value="selectedYear"
											v-for="selectedYear in years"
											:selected="selectedYear == year">{{ selectedYear }}</option>
						</select>
					</div>
				</div>

				<section class="section--week-questions-details">
					<div class="card shadow empty-state flex-col flex-center flex-item--100"
							 v-show="!weekQuestions.length">
						<span>Aucune question pour le moment</span>
					</div>

					<div class="card shadow question-table-wrapper flex-item--100"
							 v-show="weekQuestions.length">
						<vue-good-table ref="weekQuestionsTable"
														:columns="columnsTitles"
														:rows="weekQuestions"
														styleClass="vgt-table striped"
														mode="remote"
														:paginate="true"
														:pagination-options="paginationOptions"
														:totalRows="totalRecords"
														@on-page-change="onPageChange"
														@on-per-page-change="onPerPageChange">
							<template slot="table-column"
												slot-scope="props">
								<span>{{ props.column.label }}</span>
							</template>

							<template slot="table-row"
												slot-scope="props">

								<div class="question-title-wrap flex-row flex-center-v"
										 v-if="props.column.field == 'title'">
									<details class="week-question-excerpt"
													 :ref="`excerpt${props.row.id}`"
													 :data-excerpt="props.row.id"
													 @click="excerptToggle($event, props.row.id)">
										<summary class="details-title"
														 :class="props.row.is_read ? 'is-read' : ''">
											<span>{{ props.formattedRow[props.column.field] }}</span>
											<svg v-if="!!parseInt(props.row.is_starred)"
													 class="icon icon--star is-starred"
													 role="img"
													 viewBox="0 0 15 15"
													 fill="none"
													 xmlns="http://www.w3.org/2000/svg"
													 width="15"
													 height="15"><path fill="none" d="M7.5 12.04l-4.326 2.275L4 9.497.5 6.086l4.837-.703L7.5 1l2.163 4.383 4.837.703L11 9.497l.826 4.818L7.5 12.041z" stroke="#3aaa35" stroke-linecap="round" stroke-linejoin="round"></path></svg>
										</summary>

										<div class="details-body">
											<div class="week-question-details">
												<div class="message"
														 v-html="props.row.details"></div>
												<footer>
													<div class="user-card">
														<header>{{ props.row.practician }}</header>
													</div>
													<div class="links">
														<router-link class="flex-item--grow"
																				 :to="`/question/${props.row.id}`">Afficher la question</router-link>
														<button type="button"
																		class="button--link"
																		@click.prevent="toggleExpertsChat(props.row, true)">Échange entre experts</button>
													</div>
												</footer>
											</div>

											<p class="loading flex-row flex-center-v flex-gap-s"
												 v-if="typeof answers[props.row.id] === 'undefined'">
												<svg class="loader"
														 style="width:1.5rem; height: auto;"
														 width="44"
														 height="44"
														 viewBox="0 0 44 44"
														 xmlns="http://www.w3.org/2000/svg"
														 stroke="currentColor"><g fill="none" fill-rule="evenodd" stroke-width="2"><circle cx="22" cy="22" r="1"><animate attributeName="r" begin="0s" dur="1.8s" values="1; 20" calcMode="spline" keyTimes="0; 1" keySplines="0.165, 0.84, 0.44, 1" repeatCount="indefinite" /><animate attributeName="stroke-opacity" begin="0s" dur="1.8s" values="1; 0" calcMode="spline" keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1" repeatCount="indefinite" /></circle><circle cx="22" cy="22" r="1"><animate attributeName="r" begin="-0.9s" dur="1.8s" values="1; 20" calcMode="spline" keyTimes="0; 1" keySplines="0.165, 0.84, 0.44, 1" repeatCount="indefinite" /><animate attributeName="stroke-opacity" begin="-0.9s" dur="1.8s" values="1; 0" calcMode="spline" keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1" repeatCount="indefinite" /></circle></g></svg>
												<em class="text-small">Chargement des réponses…</em>
											</p>

											<ul>
												<li v-for="answer in answers[props.row.id]"
														class="week-question-answer">
													<div class="message"
															 v-html="answer.details"></div>
													<!-- <div class="user-card">
														<header>{{ answer.user.firstname }} {{ answer.user.lastname }}</header>
														<div>- {{ answer.date_modified }}</div>
													</div> -->
												</li>
											</ul>
										</div>
									</details>
								</div>

								<!-- Any other column (number, date, …) -->
								<span v-else>
									{{ props.formattedRow[props.column.field] }}
								</span>
							</template>

							<div slot="emptystate">
								<p class="text-center">
									Aucune question ne correspond aux filtres sélectionnés.
								</p>
							</div>
						</vue-good-table>
					</div>
				</section>
			</section>
		</div>

		<experts-chat v-if="currentQuestion"
									:open="modalExpertsChatVisible"
									:status="modalExpertsChatVisible"
									:question="currentQuestion"
									:messages="messages"
									:minimized="modalExpertsChatMinimized"
									@minimize="handleModalExpertsChatMinimize"
									@modalClose="modalClose" />
	</article>
</template>


<script>
import { HTTP } from "../http-common.js";
import isMobile from "ismobilejs";
import { VueGoodTable } from "vue-good-table";
import ExpertsChat from "@/components/ExpertsChat.vue";

// Component
export default {
	name: "WeekQuestions",
	props: {
		year: {
			default() {
				let year = new Date();
				return year.getFullYear();
			},
		},
		week: {
			default() {
				let week = this.$dayjs().isoWeek();
				return week;
			},
		},
	},
	components: {
		VueGoodTable,
		ExpertsChat,
	},

	data: function () {
		return {
			API_URL: HTTP.defaults.baseURL,
			userToken: this.$store.state.userToken,
			weekQuestions: [],
			answers: [],

			// Modals status
			modalExpertsChatVisible: false,
			modalExpertsChatMinimized: false,
			currentQuestion: null,

			columnsTitles: [
				{
					label: "Titre de la question",
					field: "title",
					sortable: false,
					tdClass: "",
				},
				{
					label: "Date",
					field: "date_created",
					type: "date",
					dateInputFormat: "yyyy-MM-dd H:mm:s",
					dateOutputFormat: "dd/MM/yyyy H:mm",
					sortable: false,
					tdClass: "justify-top",
					width: "200px",
				},
				{
					label: "Affectation",
					field: "expert",
					sortable: false,
					tdClass: " justify-top",
					width: "400px",
				},
			],
			// Table server mode
			totalRecords: 0,
			serverParams: {
				columnFilters: {},
				sort: [
					{
						field: "",
						type: "",
					},
				],
				page: 1,
				perPage: 20,
			},
			// Table pagination
			paginationOptions: {
				enabled: true,
				currentPage: 1,
				nextLabel: "suivant",
				prevLabel: "précédent",
				rowsPerPageLabel: "lignes par page",
				ofLabel: "sur",
				perPage: 20,
				pageLabel: "page", // for 'pages' mode
				allLabel: "Toutes",
			},
		};
	},

	computed: {
		// Current user data
		user: function () {
			return this.$store.state.userData;
		},

		// Is the user an admin? (role 4)
		user_is_admin: function () {
			return this.user.roles.indexOf(4) !== -1;
		},

		// Is the user a coordinator? (role 3)
		user_is_coordinator: function () {
			return this.user.roles.indexOf(3) !== -1;
		},

		// Is the user an expert? (role 2)
		user_is_expert: function () {
			return this.user.roles.indexOf(2) !== -1;
		},

		// Is the user an expert extended? (role 5)
		user_is_expert_ext: function () {
			return this.user.roles.indexOf(5) !== -1;
		},

		// Is a regular user?
		user_is_regular: function () {
			let r = this.user.roles.reduce((acc, val, i, roles) => acc + parseInt(val));
			return r < 2;
		},

		// Récupère la liste des années depuis la mise en ligne de l'appli
		years: function () {
			const applicationStartYear = 2021;
			let currentDate = new Date();
			let years = [];
			for (let i = applicationStartYear; i <= currentDate.getFullYear(); i++) {
				years.push(i);
			}
			return years;
		},

		weeks: function () {
			let weeksCount = this.$dayjs(this.year + "-01-01").isoWeeksInYear();
			return weeksCount;
		},

		onMobile() {
			return isMobile(window.navigator).any;
		},

		currentQuestionId() {
			return this.$store.state.currentQuestionId;
		},

		messages() {
			return this.$store.state.chatMessages;
		},
	},

	methods: {

		// Get question answers
		async getAnswers(qid) {

			// skip already loaded answers
			if (this.answers[qid]) return;

			this.$store
				.dispatch("GET_ANSWERS", qid)
				.then((answers) => {
					this.$set(this.answers, qid, answers)
				})
				.catch((error) => {
					console.error("GET_ANSWERS", error);
				});
		},

		// handleModalExpertsChatMinimize
		handleModalExpertsChatMinimize(status) {
			this.modalExpertsChatMinimized = status;
		},

		// toggle scroll lock
		toggleScrollLock(status = true) {
			const root = document.getElementsByTagName('html')[0];
			if (status) root.classList.add('scroll-locked');
			else root.classList.remove('scroll-locked');
		},

		// toggle experts chat modal
		toggleExpertsChat: async function (question, status = null) {
			// toggle or forced status
			const _status = status === null ? !this.modalExpertsChatVisible : status;

			// Close modal
			if (!_status) {
				return this.modalClose();
			}

			// Set current question
			this.currentQuestion = question;
			this.$store.dispatch("CURRENT_QUESTION", question.id);

			// Load messages then show modal
			await this.$store
				.dispatch("GET_EXPERTS_CHAT_MESSAGES", question.id)
				.catch((error) => {
					console.error("GET_EXPERTS_CHAT_MESSAGES", error);
				})
				.finally(() => {
					if (this.onMobile) {
						this.toggleScrollLock(true);
					}
					this.modalExpertsChatMinimized = false;
					this.modalExpertsChatVisible = true;
				});
		},


		// Mark question as read (for current user)
		updateReadStatus(questionID) {
			const qIndex = this.weekQuestions.findIndex(q => parseInt(q.id) === parseInt(questionID));
			const q = this.weekQuestions.find(q => parseInt(q.id) === parseInt(questionID));
			if (!q || qIndex === -1 || q.is_read) return;
			// update database
			this.$store.dispatch("PUT_QUESTION_READ_STATUS", { questionID: questionID });
			// update locally
			q.is_read = true;
			this.$set(this.weekQuestions, qIndex, q)
		},

		// Récupération des questions par semaine
		getWeekQuestions() {
			let year = this.year;
			let week = this.week;
			let page = this.serverParams.page;
			let perPage = this.serverParams.perPage;

			this.$store
				.dispatch("GET_WEEK_QUESTIONS", { year, week, page, perPage })
				.then((payload) => {
					if (!payload || !Object.entries(payload).length) {
						payload.weekQuestions = [];
						payload.totalRecords = 0;
					}

					this.weekQuestions = payload.weekQuestions.sort((a, b) => parseInt(a.is_starred) ? -1 : 1);
					this.totalRecords = payload.totalRecords ? payload.totalRecords : 0;
				})
				.catch((error) => {
					console.error("getWeekQuestions error", error);
				});
		},

		/**
		 * Reset current page
		 *
		 * @return void
		 */
		resetPaginationCurrentPage() {
			// reset current page
			this.$store.dispatch("SAVE_WEEK_QUESTIONS_CURRENT_PAGE", 1);
			// update params
			this.updateParams({ page: 1, currentPage: 1 });
			// reset table
			this.$refs.weekQuestionsTable.reset();
		},

		// on modifie l'url lorsque l'on selectionne une semaine
		selectWeekForWeekQuestions: function (event) {
			let selectedWeek = event.target.value;

			// reset pagination
			this.resetPaginationCurrentPage();

			// load questions
			this.$router.push(`/week-questions/${this.year}/${selectedWeek}`);
			// window.location.href = `/week-questions/${this.year}/${selectedWeek}`;
		},

		// on modifie l'url lorsque l'on selectionne une année
		selectYearForWeekQuestions: function (event) {
			let selectedYear = event.target.value;
			this.$router.push(`/week-questions/${selectedYear}/${this.week}`);
		},

		async excerptToggle(e, rowID) {
			// open/close accordions
			let details = e.target.closest("details");
			let allDetails = this.$refs?.weekQuestionsTable?.$el.querySelectorAll("[data-excerpt]");

			if (allDetails) {
				allDetails.forEach((elt) => {
					if (elt.getAttribute("data-excerpt") !== details.getAttribute("data-excerpt")) elt.removeAttribute("open");
				});
			}

			// opening an accordion
			if (!details.hasAttribute('open')) {

				// load data
				await this.getAnswers(rowID);

				// scroll element into view if the details was *not* open when clicked.
				this.$refs[`excerpt${rowID}`].scrollIntoView({ behavior: "smooth", block: 'start', inline: 'start' });
				this.$refs.weekQuestionsTable.$el.querySelector('.vgt-responsive').scrollTo({
					left: 0,
					behavior: 'smooth',
				});
				// mark question as read
				this.updateReadStatus(rowID);
			}
		},

		excerptCloseAll() {
			let allDetails = this.$refs?.weekQuestionsTable?.$el.querySelectorAll("[data-excerpt]");
			if (!allDetails) return;
			allDetails.forEach(($) => $.removeAttribute("open"));
		},

		// Récupère les dates formattées correspondantes à un numéro de semaine
		getFormattedWeekDates: function (weekNumber) {
			// pour avoir les dates correspondant a 1 semaine donnée
			// on prend une date de l'année que l'on veut et on force le numero de semaine
			// on ne prend pas le 1er janvier car la semaine 1 ne démarre pas forcement au premier janvier
			let week = this.$dayjs(this.year + "-02-01").isoWeek(weekNumber);

			let firstDayOfWeek = week.weekday(0);
			let lastDayOfWeek = firstDayOfWeek.add(6, "day");
			let formattedWeekDates = "du " + firstDayOfWeek.format("DD/MM") + " au " + lastDayOfWeek.format("DD/MM");

			return formattedWeekDates;
		},

		// close chat modal, remove body scroll lock, clean state
		modalClose: function (event) {
			this.modalExpertsChatVisible = false;
			this.toggleScrollLock(false);
			this.currentQuestion = null;
			this.$store.dispatch("CURRENT_QUESTION", 0);
			this.$store.dispatch("RESET_EXPERTS_CHAT_MESSAGES");
		},

		// Changement de page de la table
		onPageChange(params) {
			// close all
			this.excerptCloseAll();

			// save pagination preferences
			this.$store.dispatch("SAVE_WEEK_QUESTIONS_CURRENT_PAGE", params.currentPage);

			// update params and reload questions
			this.updateParams({ page: params.currentPage });

			this.getWeekQuestions(); // vérifier si nécessaire
		},

		// Changement du nombre de lignes par page de la table
		onPerPageChange(params) {
			// close all
			this.excerptCloseAll();

			// save pagination preferences
			this.$store.dispatch("SAVE_WEEK_QUESTIONS_CURRENT_PER_PAGE", params.currentPerPage);

			// update params and reload questions
			this.updateParams({ perPage: params.currentPerPage });

			this.getWeekQuestions(); // vérifier si nécessaire
		},

		updateParams(newProps) {
			this.serverParams = Object.assign({}, this.serverParams, newProps);
		},
	},

	mounted() {
		// Not for regular users
		if (this.user_is_regular) {
			this.$router.push("/dashboard");
		}

		// Load pagination preferences
		this.paginationOptions = { ...this.paginationOptions, ...this.$store.state.currentWeekQuestionsPaginationOptions };

		// Display last page
		if (this.paginationOptions.savedCurrentPage) {
			this.updateParams({ page: this.paginationOptions.savedCurrentPage });
			delete this.paginationOptions.savedCurrentPage;
		}

		// Load week questions
		this.getWeekQuestions();
	},

	watch: {
		// react to route changes...
		$route(to, from) {
			// If switch from specific week to current, reset pagination
			if (from.params?.week && from.params.year && !to.params?.week && !to.params?.year) {
				this.resetPaginationCurrentPage();
			}

			// close exercepts
			this.excerptCloseAll();

			// close modal
			this.modalClose();

			// load questions
			this.getWeekQuestions();
		}
	},

	beforeDestroy() {
		this.modalClose();
	},
};
</script>

<style lang="scss">
@import "@/scss/week-questions.scss";
</style>
